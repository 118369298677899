var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c("h6", [_vm._v("Polizze Vive")]),
          _vm._v(" "),
          _c("table-policies", {
            ref: "InsurancePolicyDetailTableRef1",
            attrs: {
              infomodalName: "P1",
              fields: _vm.fields,
              repository: "insurance_policy",
              resource: "insurance_policies",
              filterName: _vm.filterName1,
              filterOn: {
                byRelations: ["byDocument", "byBroker", "byTask"],
              },
              onlyActions: ["infomodal"],
              noInnerWidth: "",
              noInputPaginator: "",
              sortField: "covered_at",
              includeBottomRow: "",
              noPagination: "",
            },
            on: {
              open_note: _vm.openNoteModal,
              open_document: _vm.openDocumentModal,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "closed-insurance-policies" },
        [
          _c("h6", [_vm._v("Polizze Estinte")]),
          _vm._v(" "),
          _c("table-policies", {
            ref: "InsurancePolicyDetailTableRef2",
            attrs: {
              infomodalName: "P2",
              fields: _vm.fields,
              repository: "insurance_policy",
              resource: "insurance_policies",
              filterName: _vm.filterName2,
              filterOn: {
                byRelations: ["byDocument", "byBroker", "byTask"],
              },
              onlyActions: ["infomodal"],
              noInnerWidth: "",
              noInputPaginator: "",
              sortField: "expired_at",
              descending: true,
              includeBottomRow: "",
              noPagination: "",
            },
            on: {
              open_note: _vm.openNoteModal,
              open_document: _vm.openDocumentModal,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("note-detail-modal", {
        attrs: { items: _vm.notes, id: "noteDetailModalPolicy" },
      }),
      _vm._v(" "),
      _c("document-detail-modal", {
        attrs: { items: _vm.documents, id: "documentDetailModalPolicy" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }